export { default as Svg01 } from './01'
export { default as Svg02 } from './02'
export { default as Svg03 } from './03'
export { default as Svg04 } from './04'
export { default as Svg05 } from './05'
export { default as Svg06 } from './06'
export { default as Svg07 } from './07'
export { default as Svg08 } from './08'
export { default as Svg09 } from './09'
export { default as Svg10 } from './10'
export { default as Svg11 } from './11'
export { default as Svg12 } from './12'
export { default as Svg13 } from './13'
export { default as Svg14 } from './14'
export { default as Svg15 } from './15'
export { default as Svg16 } from './16'
export { default as Svg17 } from './17'
export { default as Svg18 } from './18'
export { default as Svg19 } from './19'
export { default as Svg20 } from './20'
