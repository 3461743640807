import styled from 'styled-components';
import { adaptForLargeScreen } from '@utils/adaptForLargeScreen';
import {
  PAGE_MARGIN_TOP,
  INNER_MARGIN,
  BODY_FONT_SMALL,
  INNER_MARGIN_RIGHT_MOBILE,
  INNER_MARGIN_LEFT_MOBILE,
  PAGE_MARGIN_TOP_LARGE,
  BODY_FONT_SMALL_MOBILE,
} from '@config/layout';
import { mobileMQ } from '@config/mediaQueries';
import { ResponsiveImage } from './ResponsiveImage';

export const GridContainer = styled.div(
  adaptForLargeScreen({
    marginLeft: INNER_MARGIN,
    marginRight: INNER_MARGIN,
    marginTop: PAGE_MARGIN_TOP,
    fontSize: BODY_FONT_SMALL,

    [mobileMQ]: {
      marginLeft: INNER_MARGIN_LEFT_MOBILE,
      marginRight: INNER_MARGIN_RIGHT_MOBILE,
      marginTop: PAGE_MARGIN_TOP_LARGE,
      fontSize: BODY_FONT_SMALL_MOBILE,
      paddingBottom: 40,
    },
  }),
);

export const GridItem = styled.div<{ landscape?: boolean }>(() => ({
  ...adaptForLargeScreen({
    width: 'calc(33.3% - 20px)',

    [mobileMQ]: {
      width: '100%',
    },
  }),
}));

export const GridImageContainer = styled.div<{ landscape: boolean }>(p =>
  adaptForLargeScreen({
    overflow: 'hidden',
    height: (p.landscape ? 728 : 1440) * 0.505, // lol magic number for height,
    position: 'relative',
    outline: 'none',

    [mobileMQ]: {
      height: 'auto',
    },
  }),
);

export const GridImage = styled(ResponsiveImage)(
  adaptForLargeScreen({
    width: '100%',

    [mobileMQ]: {
      display: 'block', // desktop was built with inline heh
    },
  }),
);

export const GridText = styled.div(
  adaptForLargeScreen({
    marginTop: 22.5,
    marginBottom: 62,
    [mobileMQ]: { marginTop: 10, marginBottom: 43 },
  }),
);
